<template>
  <div>
    <PageHeader :items="items" />
    <div class="row mb-4">
      <div class="col-md-4">
        <div class="form-group">
          <label for="cars">{{ $t("First Name") }}</label>
          <input
            id="firstName"
            v-model="form.firstName"
            name="title"
            class="form-control"
            type="text"
          />
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label for="cars">{{ $t("Last Name") }}</label>
          <input
            id="lastName"
            v-model="form.lastName"
            name="title"
            class="form-control"
            type="text"
          />
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label for="cars">{{ $t("Email") }}</label>
          <input
            id="email"
            v-model="form.email"
            name="title"
            class="form-control"
            type="text"
          />
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label for="cars">{{ $t("City") }}</label>
          <input
            id="title"
            v-model="form.city"
            name="title"
            class="form-control"
            type="text"
          />
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label for="cars">{{ $t("Street") }}</label>

          <input
            id="street"
            v-model="form.street"
            name="street"
            class="form-control"
            type="text"
          />
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label for="cars">{{ $t("Street Number") }}</label>
          <input
            id="streetNumber"
            v-model="form.streetNumber"
            name="streetNumber"
            class="form-control"
            type="text"
          />
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label for="cars">{{ $t("ZIP") }}</label>
          <input
            id="zip"
            v-model="form.zip"
            name="zip"
            class="form-control"
            type="text"
          />
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label for="cars">{{ $t("Mobile") }}</label>
          <input
            id="mobile"
            v-model="form.mobile"
            name="mobile"
            class="form-control"
            type="text"
          />
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label for="cars">{{ $t("Phone") }}</label>
          <input
            id="phoneNo"
            v-model="form.phoneNo"
            name="phoneNo"
            class="form-control"
            type="text"
          />
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label for="cars">{{ $t("Roles") }}</label>
          <multiselect
            v-model="form.roles"
            label="title"
            :multiple="true"
            track-by="id"
            :options="roles"
            selectLabel=""
            deselectLabel=""
          />
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label for="cars">{{ $t("Customer") }}</label>
          <MultiSelectInput
            v-model="form.company_id"
            :options="customers"
            label="companyName"
            trackBy="id"
            :key="form.company_id"
            moduleName="customers"
            :multiple="false"
          >
          </MultiSelectInput>
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label for="cars">{{ $t("Types") }}</label>
          <multiselect
            v-model="form.types"
            :multiple="true"
            :options="types"
            selectLabel=""
            deselectLabel=""
          />
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label for="cars">{{ $t("Password") }}</label>
          <input
            id="password"
            v-model="form.password"
            name="title"
            class="form-control"
            type="password"
          />
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label for="cars">{{ $t("Confirm Password") }}</label>

          <input
            id="confirmPassword"
            v-model="form.confirmPassword"
            name="title"
            class="form-control"
            type="password"
          />

          <div v-if="!passwordsMatch" style="color: red">
            {{ $t("Password and confirm password should be matched") }}
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-end">
      <div class="mt-4 max-w-3xl flex">
        <b-button @click="updateUser()" class="mb-2 cursor-pointer">
          <span class="mr-25 align-middle">{{ $t("Update User") }}</span>
          <feather-icon icon="PlusIcon" size="12" />
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import TextInput from "@/components/TextInput.vue";
import PageHeader from "@/components/PageHeader.vue";
import Multiselect from "vue-multiselect";
import MultiSelectInput from "@/components/MultiSelectInput.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    TextInput,
    PageHeader,
    Multiselect,
    MultiSelectInput,
  },
  data() {
    return {
      submitted: false,
      form: {
        firstName: "",
        lastName: "",
        email: "",
        city: "",
        street: "",
        streetNumber: "",
        zip: "",
        mobile: "",
        phoneNo: "",
        roles: [],
        types: [],
        password: "",
        confirmPassword: "",
        company_id: "",
      },

      types: ["customer_employee", "employee", "partner"],
      passwordsMatch: true,
    };
  },
  computed: {
    ...mapGetters("roles", ["roles"]),
    ...mapGetters("customers", ["customers"]),
    items() {
      return [
        {
          text: this.$t("Dental Twin"),
          to: "/home",
        },
        {
          text: this.$t("Settings"),
          to: "/settings",
        },
        {
          text: this.$t("Users"),
          to: "/users",
        },
        {
          text: this.$t("Edit"),
          active: true,
        },
      ];
    },
  },
  async mounted() {
    await this.$store.dispatch("roles/list", {
      limit_start: 0,
      limit_count: 100,
    });
    await this.$store.dispatch("customers/list");
    this.refresh();
  },
  methods: {
    async refresh() {
      // try {
      //   await this.$store.dispatch("roles/showRoleList", {
      //     limit_start: 0,
      //     limit_count: 100,
      //   });
      // } catch (e) {}
      this.$store
        .dispatch("users/show", {
          id: this.$route.params.id,
        })
        .then(async (res) => {
          if (res.message_type === "error") {
            // this.toast.fire({
            //   icon: "error",
            //   title: res?.message,
            // });
            // this.$store.commit("notificationMessages/notificationMessages", {
            //   icon: "error",
            //   title: res?.message,
            //   msg: "User Notification",
            // });
          } else {
            var response = res?.data;
            this.form.street = response.street;
            this.form.streetNumber = response.street_number;
            this.form.zip = response.zip;
            this.form.firstName = response.first_name;
            this.form.lastName = response.last_name;
            this.form.company_id = response.company_id;
            this.form.email = response.email;
            this.form.city = response.city;
            this.form.phoneNo = response.phone;
            this.form.types = response.types;
            this.form.types = Object.keys(this.form.types).filter(
              (type) => this.form.types[type] == 1
            );
            this.form.roles = response.roles
              .map((roleId) => {
                return {
                  ...(this.roles.find((role) => role.id == roleId) ?? {}),
                };
              })
              .filter((role) => role.id);

            this.form.types = Object.keys(response.types).filter(
              (type) => this.form.types[type] == 1
            );
            let company =
              this.customers?.data?.find(
                (company) => company.id === this.form.company_id
              ) ?? "";
            // if the company does not exist in the companies listing then use the show API to fetch the company separately
            if (!company && this.form.company_id) {
              let res = await this.$store.dispatch(
                "customers/show",
                this.form.company_id
              );
              company = res?.data?.modelData ?? "";
            }
            this.form.company_id = company;
          }
        });
    },
    async updateUser() {
      this.submitted = true;
      this.passwordsMatch = true;
      const payload = {
        id: this.$route.params.id,
        first_name: this.form.firstName,
        last_name: this.form.lastName,
        mail: this.form.email,
        phone: this.form.phoneNo,
        city: this.form.city,
        street: this.form.street,
        types: this.form.types,
        street_number: this.form.streetNumber,
        zip: this.form.zip,
        company_id: this.form.company_id?.id ?? "",
        roles:
          this.form.roles && this.form.roles.length
            ? this.form.roles.map((role) => role.id)
            : [],
      };

      if (this.form.password) {
        payload.password = this.form.password;
        if (this.form.password !== this.form.confirmPassword) {
          this.passwordsMatch = false;
          return false;
        }
      }

      this.isLoading = true;
      const response = await this.$store.dispatch("users/update", payload);
      this.isLoading = false;
      if (
        response.hasOwnProperty("message_type") &&
        response.message_type == "error"
      ) {
        // this.toast.fire({
        //   icon: "error",
        //   title: response?.message,
        // });
        // this.$store.commit("notificationMessages/notificationMessages", {
        //   icon: "error",
        //   title: response?.message,
        // });
      } else {
        // const message = {
        //   icon: "success",
        //   title: this.$t("User") + " " + this.$t("updated successfully"),
        // };
        // this.toast.fire(message);
        // this.$store.commit("notificationMessages/notificationMessages", {
        //   icon: "success",
        //   title: "User updated successfully",
        //   msg: "User Notification",
        // });

        await this.$router.push({ path: "/users" });
      }
    },
  },
};
</script>

<style>
#nprogress {
  position: relative;
  z-index: 9999999;
}

.white-color {
  color: white !important;
}

.vgt-responsive {
  overflow-x: visible !important;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
